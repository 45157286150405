<template>
<div>
    <v-dialog v-model="$store.state.dialog" width="600" persistent>
        <v-card flat class="card">
            <v-toolbar outlined flat >
                <v-toolbar-name>{{$store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل'}} {{this.$route.meta.single}}</v-toolbar-name>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" v-model="valid">
                <v-container>
                    <v-card outlined>
                        <v-container>

                            <v-row>
                                <aTextField v-model="item.name" label="العنوان" :cols="12" :md="12" :required="true" :fieldname="true"/>
                                <v-col cols="12">
                                    <VueEditor v-model="item.content"/>
                                </v-col>
                                <v-img :src="$url+$global.state.path"/>
                                <MultipartFileUpload endPoint="UploadFiles" buttonText="رفع صورة" formData="files"/>
                            </v-row>

                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :disabled="!valid || $global.state.path == ''" :loading="$global.state.loading" x-large color="primary" class="btn">حفظ</v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</div>
</template>

<script> 
import { VueEditor } from "vue2-editor";

export default {

    components:{
        MultipartFileUpload: ()=> import('@/components/MultipartFileUpload'),
        VueEditor
    },  

    data() {
        return {
            item: {},
            search: '',
            valid: false
        }
    },

    created() {
        this.reset()

        this.$eventBus.$on('fill-fields', () => {
            this.item = this.$store.state.itemDetails
            this.$global.state.path = this.$store.state.itemDetails.imageUrl
            this.item.imageUrl = this.$store.state.itemDetails.imageUrl
        })
       
    },

    methods: {

        reset() {
            this.item = {
                "name": "",
                "imageUrl": "",
                "content": "",
            },
            this.$global.state.path = null
        },

        submit() {
            this.item.type = this.$route.meta.type
            this.item.imageUrl = this.$global.state.path
            if (this.$store.state.itemDetails.dialogType == 'add') {
                this.addItem()
            } else {
                this.editItem()
            }
        },

        addItem() {
            this.$global.state.loading = true
            this.$http.post(`${this.$route.meta.endPoint}`, this.item).then(res => {
                this.$service.success('تم الإضافة بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
                this.close()
            }).catch(err => {
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

        editItem() {
            this.$global.state.loading = true
            this.$http.put(`${this.$route.meta.endPoint}/${this.item.id}`, this.item).then(res => {
                this.close()
                this.$service.success('تم التعديل بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
            }).catch(err => {
                // this.$service.faild(err.response.data.message)
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

        close() {
            this.$store.commit('setDialog')
            this.reset()
            this.resetValidation()
            this.$store.commit('setItemDetails', {})
        },

        resetValidation() {
            this.$refs.form.resetValidation()
        },

    },
}
</script>

